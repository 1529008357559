import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container, Typography, Box, Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Chip, IconButton, AppBar,
  Toolbar, CircularProgress, Alert, Collapse, TextField, MenuItem,
  FormControl, Select, InputLabel, Tooltip, Badge
} from '@mui/material';
import {
  Check as CheckIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
  Refresh as RefreshIcon,
  Storage as StorageIcon,
  Logout as LogoutIcon,
  Info as InfoIcon,
  Search as SearchIcon
} from '@mui/icons-material';
import { getRouters, getRouterStudies, getSystemStatus } from '../services/api';

// Constants
const REFRESH_RATES = [
  { value: 0, label: 'Manual' },
  { value: 30000, label: '30 seconds' },
  { value: 60000, label: '1 minute' },
  { value: 300000, label: '5 minutes' }
];

const SEARCH_FIELDS = [
  { value: 'facility', label: 'Facility' },
  { value: 'router_id', label: 'Router ID' },
  { value: 'router_alias', label: 'Alias' },
  { value: 'vpn_status_name', label: 'VPN Status' },
  { value: 'disk_status_name', label: 'Disk Status' }
];

// Helper functions
const getStatusColor = (status) => {
  if (!status) return 'default';
  if (status.toLowerCase().includes('ok')) return 'success';
  if (status.toLowerCase().includes('warn')) return 'warning';
  return 'error';
};

const getStatusIcon = (status) => {
  if (!status) return null;
  if (status.toLowerCase().includes('ok')) return <CheckIcon />;
  if (status.toLowerCase().includes('warn')) return <WarningIcon />;
  return <ErrorIcon />;
};

const Dashboard = () => {
  const navigate = useNavigate();
  const [routers, setRouters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [expandedRow, setExpandedRow] = useState(null);
  const [expandedContent, setExpandedContent] = useState(null);
  const [showLegend, setShowLegend] = useState(false);
  const [refreshRate, setRefreshRate] = useState(0);
  const [lastRefresh, setLastRefresh] = useState(null);
  const [searchField, setSearchField] = useState('facility');
  const [searchQuery, setSearchQuery] = useState('');

  const [user, setUser] = useState(() => {
    try {
      // Updated to handle the correct user data structure
      const userData = JSON.parse(localStorage.getItem('user') || '{}');
      console.log('Initial user data:', userData);
      return userData;
    } catch (error) {
      console.error('Error parsing user data:', error);
      return {};
    }
  });

  const fetchRouterData = async () => {
    try {
      setLoading(true);
      console.log('Dashboard: Fetching router data');
      
      const data = await getRouters();
      console.log('Router data received:', data);
      
      const sortedData = [...data].sort((a, b) => {
        const aHasError = a.vpn_status_name?.toLowerCase().includes('error') || 
                         a.disk_status_name?.toLowerCase().includes('error');
        const bHasError = b.vpn_status_name?.toLowerCase().includes('error') || 
                         b.disk_status_name?.toLowerCase().includes('error');
        return bHasError - aHasError;
      });
      
      setRouters(sortedData);
      setLastRefresh(new Date());
      setError('');
    } catch (err) {
      console.error('Dashboard: Error fetching routers:', err);
      if (err.message?.includes('401') || err.message?.includes('unauthorized')) {
        console.log('Authentication error, redirecting to login');
        navigate('/login');
        return;
      }
      setError(err.message || 'Failed to load router data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Updated authentication check
    if (!user?.accessToken) {
      console.log('No access token found, redirecting to login');
      navigate('/login');
    } else {
      console.log('Access token found, fetching router data');
      fetchRouterData();
    }
  }, [navigate, user?.accessToken]); // Updated dependency

  const handleLogout = () => {
    console.log('Logging out...');
    localStorage.removeItem('user');
    setUser({});
    navigate('/login');
  };
 

  const handleRowClick = async (routerId, type) => {
    if (expandedRow === routerId && expandedContent?.type === type) {
      setExpandedRow(null);
      setExpandedContent(null);
      return;
    }

    try {
      let content;
      if (type === 'studies') {
        const studies = await getRouterStudies(routerId);
        content = { type, data: studies };
      } else if (type === 'status') {
        const status = await getSystemStatus(routerId);
        content = { type, data: status };
      }
      setExpandedRow(routerId);
      setExpandedContent(content);
    } catch (err) {
      console.error(`Error fetching ${type}:`, err);
      setError(`Failed to load ${type} data: ${err.message}`);
    }
  };

  const filteredRouters = routers.filter(router => {
    const value = router[searchField]?.toString().toLowerCase() || '';
    return value.includes(searchQuery.toLowerCase());
  });

  
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <StorageIcon sx={{ mr: 2 }} />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Router Dashboard
          </Typography>
          {user?.user && (
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              mr: 2,
              backgroundColor: 'rgba(255,255,255,0.1)',
              padding: '4px 12px',
              borderRadius: 1
            }}>
              <Typography variant="body2" sx={{ mr: 1 }}>
                {user.user.username}
              </Typography>
              <Chip 
                size="small"
                label={user.user.role}
                color="secondary"
                sx={{ 
                  height: '20px',
                  '& .MuiChip-label': {
                    fontSize: '0.75rem',
                    px: 1
                  }
                }}
              />
            </Box>
          )}
          <Tooltip title="Logout">
            <IconButton color="inherit" onClick={handleLogout}>
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>

      <Container maxWidth="xl" sx={{ mt: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          <Typography variant="h5" component="h1">
            Router Status Overview
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Tooltip title="Show/Hide Legend">
              <IconButton onClick={() => setShowLegend(!showLegend)}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel>Refresh Rate</InputLabel>
              <Select
                value={refreshRate}
                label="Refresh Rate"
                onChange={(e) => setRefreshRate(e.target.value)}
              >
                {REFRESH_RATES.map(rate => (
                  <MenuItem key={rate.value} value={rate.value}>
                    {rate.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <IconButton onClick={fetchRouterData} disabled={loading}>
              <RefreshIcon />
            </IconButton>
          </Box>
        </Box>

        <Collapse in={showLegend}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant="subtitle2" gutterBottom>Legend</Typography>
            <Box sx={{ display: 'flex', gap: 4 }}>
              <Box>
                <Typography variant="body2" gutterBottom>Status Indicators:</Typography>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <Box sx={{ width: 16, height: 16, borderRadius: '50%', bgcolor: 'success.main' }} />
                  <Typography variant="body2">Normal</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <Box sx={{ width: 16, height: 16, borderRadius: '50%', bgcolor: 'warning.main' }} />
                  <Typography variant="body2">Warning</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <Box sx={{ width: 16, height: 16, borderRadius: '50%', bgcolor: 'error.main' }} />
                  <Typography variant="body2">Error</Typography>
                </Box>
              </Box>
              <Box>
                <Typography variant="body2" gutterBottom>Disk Usage:</Typography>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <Box sx={{ width: 50, bgcolor: 'success.main', height: 10, borderRadius: 1 }} />
                  <Typography variant="body2">&lt; 70%</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <Box sx={{ width: 50, bgcolor: 'warning.main', height: 10, borderRadius: 1 }} />
                  <Typography variant="body2">70-90%</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <Box sx={{ width: 50, bgcolor: 'error.main', height: 10, borderRadius: 1 }} />
                  <Typography variant="body2">&gt; 90%</Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Collapse>

        {lastRefresh && (
          <Typography variant="caption" sx={{ display: 'block', mb: 2 }}>
            Last refreshed: {lastRefresh.toLocaleString()}
          </Typography>
        )}

        <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel>Search Field</InputLabel>
            <Select
              value={searchField}
              label="Search Field"
              onChange={(e) => setSearchField(e.target.value)}
            >
              {SEARCH_FIELDS.map(field => (
                <MenuItem key={field.value} value={field.value}>
                  {field.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            size="small"
            label="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: <SearchIcon sx={{ color: 'action.active', mr: 1 }} />
            }}
          />
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Router ID</TableCell>
                  <TableCell>Facility</TableCell>
                  <TableCell>Alias</TableCell>
                  <TableCell>VPN Status</TableCell>
                  <TableCell>Disk Status</TableCell>
                  <TableCell>License</TableCell>
                  <TableCell>Disk Usage</TableCell>
                  <TableCell>System Status</TableCell>
                  <TableCell>Studies</TableCell>
                  <TableCell>Last Seen</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRouters.map((router) => (
                  <React.Fragment key={router.id}>
                    <TableRow>
                      <TableCell>{router.router_id}</TableCell>
                      <TableCell>{router.facility}</TableCell>
                      <TableCell>{router.router_alias}</TableCell>
                      <TableCell>
                        <Chip
                          icon={getStatusIcon(router.vpn_status_name)}
                          label={router.vpn_status_name}
                          color={getStatusColor(router.vpn_status_name)}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <Chip
                          icon={getStatusIcon(router.disk_status_name)}
                          label={router.disk_status_name}
                          color={getStatusColor(router.disk_status_name)}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={router.license_status}
                          color={router.license_status === 'active' ? 'success' : 'error'}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Box sx={{ width: '100px', bgcolor: '#f0f0f0', borderRadius: 1 }}>
                            <Box
                              sx={{
                                width: `${router.disk_usage}%`,
                                height: '10px',
                                bgcolor: router.disk_usage > 90 ? 'error.main' : 
                                       router.disk_usage > 70 ? 'warning.main' : 
                                       'success.main',
                                borderRadius: 1
                              }}
                            />
                          </Box>
                          <Typography variant="body2">{router.disk_usage}%</Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            width: 16,
                            height: 16,
                            borderRadius: '50%',
                            bgcolor: router.system_status?.some(s => s.status_code?.toLowerCase().includes('error'))
                              ? 'error.main'
                              : router.system_status?.some(s => s.status_code?.toLowerCase().includes('warn'))
                                ? 'warning.main'
                                : 'success.main',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleRowClick(router.id, 'status')}
                        />
                      </TableCell>
                      <TableCell>
                        <Badge 
                          badgeContent={router.study_count || 0} 
                          color="primary"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleRowClick(router.id, 'studies')}
                        >
                          <Typography variant="body2">View</Typography>
                        </Badge>
                      </TableCell>
                      <TableCell>
                        {new Date(router.last_seen).toLocaleString()}
                      </TableCell>
                    </TableRow>
                    {expandedRow === router.id && (
                      <TableRow>
                        <TableCell colSpan={10} sx={{ p: 0 }}>
                          {expandedContent?.type === 'studies' ? (
                            <Box sx={{ p: 2, bgcolor: 'grey.50' }}>
                              <Typography variant="subtitle2" gutterBottom>DICOM Studies in Transit</Typography>
                              <TableContainer>
                                <Table size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Accession</TableCell>
                                      <TableCell>Patient ID</TableCell>
                                      <TableCell>Patient Name</TableCell>
                                      <TableCell>Study Date</TableCell>
                                      <TableCell>Modality</TableCell>
                                      <TableCell>Description</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {expandedContent.data.map((study) => (
                                      <TableRow key={study.id}>
                                        <TableCell>{study.accession_number}</TableCell>
                                        <TableCell>{study.patient_id}</TableCell>
                                        <TableCell>{study.patient_name}</TableCell>
                                        <TableCell>{new Date(study.study_date).toLocaleDateString()}</TableCell>
                                        <TableCell>{study.modality}</TableCell>
                                        <TableCell>{study.study_description}</TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Box>
                          ) : expandedContent?.type === 'status' ? (
                            <Box sx={{ p: 2, bgcolor: 'grey.50' }}>
                              <Typography variant="subtitle2" gutterBottom>System Status Details</Typography>
                              <TableContainer>
                                <Table size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Container</TableCell>
                                      <TableCell>Status</TableCell>
                                      <TableCell>Last Updated</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {expandedContent.data.map((status) => (
                                      <TableRow key={status.container_number}>
                                        <TableCell>Container {status.container_number}</TableCell>
                                        <TableCell>
                                          <Chip
                                            size="small"
                                            label={status.status_code}
                                            color={getStatusColor(status.status_code)}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          {new Date(status.updated_at).toLocaleString()}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Box>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>
    </Box>
  );
};

export default Dashboard;