// frontend/src/services/api.js

const API_BASE_URL = process.env.REACT_APP_API_URL?.trim().replace(/\/+$/, '') || 'http://18.206.228.24:3001/api';

console.log('API Configuration:', {
  baseUrl: API_BASE_URL,
  environment: process.env.NODE_ENV
});

// Helper function to validate token
const validateToken = (token) => {
  try {
    const tokenParts = token.split('.');
    if (tokenParts.length !== 3) return false;
    const tokenPayload = JSON.parse(atob(tokenParts[1]));
    return tokenPayload.exp * 1000 > Date.now();
  } catch (error) {
    return false;
  }
};

// Function to check if the token has expired
const isTokenExpired = (token) => {
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    const currentTime = Math.floor(Date.now() / 1000);
    // Add a 30-second buffer to handle network latency
    return (payload.exp - 30) <= currentTime;
  } catch (error) {
    return true;
  }
};

// Get a valid token (either existing or refreshed)
const getValidToken = async () => {
  try {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (!user.accessToken) return null;

    // Check if the current token is valid
    if (!isTokenExpired(user.accessToken)) {
      return user.accessToken;
    }

    // Token is expired, try to refresh
    if (user.refreshToken) {
      const response = await fetch(`${API_BASE_URL}/auth/refresh-token`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refreshToken: user.refreshToken })
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('user', JSON.stringify({
          ...user,
          accessToken: data.accessToken,
          refreshToken: data.refreshToken
        }));
        return data.accessToken;
      }
    }

    // If refresh failed or no refresh token
    localStorage.removeItem('user');
    window.location.href = '/login';
    return null;
  } catch (error) {
    console.error('Token validation failed:', error);
    localStorage.removeItem('user');
    window.location.href = '/login';
    return null;
  }
};

// Refresh Token Function
const refreshAccessToken = async () => {
  try {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (!user.refreshToken) {
      throw new Error('No refresh token available');
    }

    const response = await fetch(`${API_BASE_URL}/auth/refresh-token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ refreshToken: user.refreshToken })
    });

    if (!response.ok) {
      throw new Error('Failed to refresh token');
    }

    const data = await response.json();
    
    localStorage.setItem('user', JSON.stringify({
      ...user,
      accessToken: data.accessToken,
      refreshToken: data.refreshToken
    }));

    return data.accessToken;
  } catch (error) {
    console.error('Token refresh failed:', error);
    localStorage.removeItem('user');
    window.location.href = '/login';
    throw error;
  }
};

// Fetch with authentication
const fetchWithAuth = async (endpoint, options = {}) => {
  try {
    const token = await getValidToken();
    if (!token) {
      throw new Error('No valid token available');
    }

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      ...options.headers,
    };

    const response = await fetch(`${API_BASE_URL}/${endpoint}`, {
      ...options,
      headers,
    });

    if (!response.ok) {
      const error = await response.json().catch(() => ({}));
      throw new Error(error.message || 'API request failed');
    }

    return response.json();
  } catch (error) {
    console.error('API Request Failed:', error);
    throw error;
  }
};

// API Functions
export const login = async (credentials) => {
  try {
    const response = await fetch(`${API_BASE_URL}/auth/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(credentials)
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Login failed');
    }

    const data = await response.json();
    
    if (!data.accessToken) {
      throw new Error('Invalid server response: missing access token');
    }

    localStorage.setItem('user', JSON.stringify({
      accessToken: data.accessToken,
      refreshToken: data.refreshToken,
      user: data.user
    }));

    return data;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const logout = async () => {
  try {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (user.refreshToken) {
      await fetchWithAuth('auth/logout', {
        method: 'POST',
        body: JSON.stringify({ refreshToken: user.refreshToken })
      });
    }
  } catch (error) {
    console.error('Logout error:', error);
  } finally {
    localStorage.removeItem('user');
    window.location.href = '/login';
  }
};

export const checkHealth = () => fetchWithAuth('health');

export const getRouters = () => fetchWithAuth('routers');

export const getRouterById = (id) => fetchWithAuth(`routers/${id}`);

export const getRouterStudies = (routerId) => fetchWithAuth(`routers/${routerId}/studies`);

export const getSystemStatus = (routerId) => fetchWithAuth(`routers/${routerId}/system-status`);

// Additional API functions
export const updateRouter = (id, data) => fetchWithAuth(`routers/${id}`, {
  method: 'PUT',
  body: JSON.stringify(data)
});

export const deleteRouter = (id) => fetchWithAuth(`routers/${id}`, {
  method: 'DELETE'
});

// Export all functions in an object
const apiService = {
  login,
  logout,
  checkHealth,
  getRouters,
  getRouterById,
  getRouterStudies,
  getSystemStatus,
  updateRouter,
  deleteRouter
};

export default apiService;
